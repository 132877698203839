import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '@moonshineragency/ui/src/components/Steps/Steps.module.scss';

const StepsContext = React.createContext();
const Step = ({ title, id, icon, children }) => {
  const { active, orientation } = React.useContext(StepsContext);
  return (
    <>
      <div
        className={classNames(
          orientation === 'horizontal'
            ? styles.horizontalStep
            : styles.verticalStep,
          {
            [styles.active]: active === id,
          },
        )}
      >
        <span className={classNames('icon', icon, styles.icon)} />
        <div
          className={classNames('text-primary font-weight-bold', styles.title)}
        >
          {title}
        </div>
      </div>
      {children && (
        <div
          className={classNames(styles.description, {
            [styles.activeDescription]: active === id,
          })}
        >
          {children}
        </div>
      )}
    </>
  );
};
const Steps = ({ children, active, orientation = 'horizontal', className }) => {
  return (
    <StepsContext.Provider value={{ active, orientation }}>
      <div
        className={classNames(styles.container, styles[orientation], className)}
      >
        {children}
      </div>
    </StepsContext.Provider>
  );
};

Steps.propTypes = {
  active: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  orientation: PropTypes.string,
};

Steps.defaultProps = {
  active: '',
  className: '',
  orientation: 'horizontal',
};

Step.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
};

Step.defaultProps = {
  children: null,
  icon: null,
  id: '',
  title: '',
};

export { Steps as default, Step };
